class APIModel {
  //for server

  static URL = "http://backend.myeasycard.co/";
  static HOST = "http://backend.myeasycard.co/api/";

  // for local
  // static URL = "http://localhost:8000/"
  // static HOST = "http://localhost:8000/api/";
}
export default APIModel;
